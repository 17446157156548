import {format, distanceInWords, differenceInDays} from 'date-fns'
import React from 'react'
import PortableText from './portableText'
import Container from './container'
import Img from 'gatsby-image';
import Footer from './footer';

function BlogPost (props) {
  const {_rawBody, authors, categories, title, mainImage, publishedAt} = props
  return (
    <article className="w-full">
      {mainImage && mainImage.asset && (
        <Img fluid={mainImage.asset.fluid} objectFit="cover" className="max-w-screen-lg mx-auto" alt={mainImage.alt}/>
      )}
      <div className="lg:prose-xl mx-auto prose">
        <Container>
          <div>
            <div>
              <h1>{title}</h1>
              <span className="-mt-5 flex font-bold text-gray-800 dark:text-gray-300">
                {differenceInDays(new Date(publishedAt), new Date()) > 3 ? distanceInWords(new Date(publishedAt), new Date()) : format(new Date(publishedAt), 'MMMM Do, YYYY')} by Rijad Husic
              </span>
              {/* {authors && <AuthorList items={authors} title='Authors' />} */}
              {_rawBody && <PortableText blocks={_rawBody} />}
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </article>
  )
}

export default BlogPost
